/* connectors */
.connectors {
  display: flex;
  flex-direction: column;
  align-items:flex-end;
  transition: all 1s;
  overflow:hidden;
  height:75px;
}

.connectors svg {
  height:23px;
}

.connectors.bottom {
  right:unset;
  bottom:4em;
  left:0;
  transform: rotateZ(180deg);
}

.connectors svg:first-child {
  width:250px;
  animation-name:slideFromRight;
  animation-duration: .5s;
}

.connectors svg:last-child {
  width:300px;
  animation-name:slideFromRight;
  animation-duration: .75s;
}

.connectors.engage svg:first-child {
  width:10vw;
}

.connectors.engage svg:last-child {
  width:12vw;
}

@keyframes slideFromRight {
  0% {margin-right:-400px}
  25%{margin-right:-300px;}
  100% {margin-right:0}
}
