:root {
  --lw-blue-01: #5EADE0;
  --lw-blue-02: #265188;
  --lw-blue-03: #dff1f9;
  --lw-blue-04: #f5f9fa;
  --lw-green-01: #57ae8e;
  --lw-green-02: #B1E299;
  --lw-red-01:#8c3543;
  --lw-red-02:#F9607B;
  --lw-chart-purple: rgba(92 97 136 / 85%);
  --lw-chart-blue: rgb(12 164 197 / 85%);
  --lw-chart-green: rgb(111 207 151 / 85%);
  --lw-chart-yellow: rgb(249 188 96 / 85%);
  --lw-chart-red: rgba(249 96 123 / 85%);
  --lw-grey-toolbar: #f9f9f9;
  --lw-grey-pinline: #ddd;
  --lw-grey-mid: #bdbdbd;
  --lw-grey-dark: #797979;
  --lw-grey-darkest: #555555;
  --lw-not-black: #333;
  --lw-black: #000;
  --lw-white: #fff;
  --lw-gradient-bg: linear-gradient(180deg, #F7F7F7 0%, #FEFEFE 11.98%, #FEFEFE 86.98%, #F7F7F7 100%);
}
