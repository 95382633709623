@import "bootstrap/scss/bootstrap";

:root {
  --bs-font-sans-serif: 'Roboto Condensed', 'Roboto', sans-serif;
  --bs-primary: var(--lw-blue-02);
  --bs-secondary: var(--lw-green-01);
  --bs-btn-border-radius:0;
  --bs-success-rgb: 111,207,151;
  --bs-danger-rgb: 249, 96, 123;
}

.btn, .card{
  border-radius: 0 ;
}

.btn-primary {
  --bs-btn-bg: var(--lw-blue-02);
  --bs-btn-border-color: var(--lw-blue-02);
  --bs-btn-hover-bg: var(--lw-blue-01);
  --bs-btn-hover-border-color: var(--lw-blue-01);
  --bs-btn-active-bg: var(--lw-blue-02);
  --bs-btn-active-border-color: var(--lw-blue-02);
}

.btn-outline-primary {
  --bs-btn-color: var(--lw-blue-02);
  --bs-btn-border-color: var(--lw-blue-02);
  --bs-btn-hover-border-color: var(--lw-blue-01);
  --bs-btn-hover-bg: var(--lw-blue-01);
  --bs-btn-active-bg: var(--lw-blue-02);
  --bs-btn-active-border-color: var(--lw-blue-02);
}

.btn-secondary{
  background-color: var(--lw-green-01);
  border-color: var(--lw-green-01);
}

// offcanvas-xl is used for responsive stuff
.oc-xl {
  width: 90vw !important;
}

.oc-scrollable {
  overflow: auto;
}

.smaller {
  font-size: 0.8em;
}

.alert-sm {
  padding: 0.5rem;
}

.bg-yellow-green {
  background-color: #9ACD32;
}

.bg-blue-01 {
  background-color: var(--lw-blue-01);
}

.modal-body {
  &.alert-modal {
    border-radius: var(--bs-border-radius-lg);

    &.info {
      border-left: solid 8px var(--lw-blue-01);
      background:rgba(13 202 240 / 10%);
    }

    &.warn {
      border-left: solid 8px var(--lw-chart-yellow);
      background:rgba(249 188 96 / 10%);
    }

    &.danger {
      border-left: solid 8px var(--lw-chart-red);
      background:rgba(249 96 123 / 10%);
    }
  }
}
