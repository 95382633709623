/* Importing Bootstrap SCSS file. */
@import 'bootstrap';
@import "colors";
@import "connectors";
@import 'flatpickr/dist/flatpickr.css';

/* Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

:root {
  --box-animation-length:.5s;
  --box-animation-start-length:.25s;
  --lw-paragraph-font-family: 'Roboto Condensed', sans-serif;
  --bs-body-color: var(--lw-not-black)
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

router-outlet + * {
  display: block;  /* Change display inline to block */
  animation: fade 0.5s;
}

/* custom Brand helpers */
.brand .active{
  display:block;
}

a {
  cursor: pointer;
}

.fill-primary{
  fill:#315084;
}
.fill-secondary{
  fill:#6eaee7;
}
.fill-tertiary{
  fill:var(--lw-green-01);
}

.confidentWrong {
  color: #F9607B;
}
.unsureWrong {
  color: #E1BE89;
}
.confidentRight {
  color: #6FCF97;
}
.unsureRight {
  color: #5C6188;
}
.bg-confidentWrong {
  background-color: #F9607B;
}
.bg-unsureWrong {
  background-color: #E1BE89;
}
.bg-confidentRight {
  background-color: #6FCF97;
}
.bg-unsureRight {
  background-color: #5C6188;
}
h1 {
  letter-spacing:-1px;
}
p{
  font-family:var(--lw-paragraph-font-family);
  font-weight: 300;
  font-size: 1.3em;
}
.btn.hover-arrow fa-icon {
  transition:all .5s;
}
.btn.hover-arrow:hover fa-icon {
  margin-left:20px !important;
  transition:margin-left .25s;
}
.hide {
  display:none !important;
}
.clickable {
  cursor:pointer;
}
.blue-bg{
  background:var(--lw-blue-03);
  transition:all .25s;
}

.activity-element-title{
  font-size:15px;
  font-weight:400;
  color:var(--lw-grey-dark);
}

.current-activity img,
.current-activity iframe,
.current-activity video,
.current-activity audio,
.current-activity figure{
  max-width:75%;
  max-height:500px;
  display:block;
  margin:1em 0;
}

.alert{
  border:0;
  text-align:left;
}
.alert.alert-success {
  border-left: solid 8px var(--lw-green-01);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.alert.alert-danger {
  border-left: solid 8px var(--lw-red-02);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.alert.alert-info {
  border-left: solid 8px var(--lw-blue-01);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.alert-success,
.alert-danger,
.alert-warning,
.alert-info{
  color:var(--lw-grey-dark);
  border-width:0;
  border-left-width: 4px;
}

.alert-success{
  border-color:var(--lw-green-01);
  background:rgb(177 226 153 / 14%);
}

.alert-danger{
  border-color:var(--lw-chart-red);
  background:rgba(249 96 123 / 5%);
}

.alert.alert-warning{
  border-left:4px solid burlywood;
  background: #ffe69c;
}

.alert-info{
  border-color:var(--lw-blue-01);
  background:var(--lw-blue-04);
}

.alert p:last-of-type {
  margin-bottom: 0;
}

/* Responsive */
@media screen and (max-width:991px){

  .current-activity img,
  .current-activity iframe,
  .current-activity video,
  .current-activity audio,
  .current-activity figure{
    max-width:100%;
    object-fit:cover;
  }
}

// used for drag drop cursor
body.inherit-cursors * {
  cursor: inherit !important;
}

body.disable-select * {
  user-select: none;
}

.history {
  .explanation {
    iframe, video, audio, figure {
      max-width: 100%;
      object-fit:cover;
    }
  }
}
